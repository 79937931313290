import React,{ useId } from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link, useSearchParams,useNavigate } from "react-router-dom";
import mapboxgl from 'mapbox-gl';

import AppBar from '../components/AppBar.js';
import BackButton from '../components/buttons/BackButton.js';
import googleMap from '../images/Google_Maps.svg'

import { AuthContext } from '../App';
import { ThemeContext } from '../App';
import { urlContext } from '../App';
import { keyContext } from '../App';

mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZW5zdHJlYW0iLCJhIjoiY2xubTMybmMzMmRyZTJtcnU0MW1jNG04biJ9.YY7mRsFGnjRR-a1ZYd_5TA';

const initSite = {
    active:false,
    address: {
        address_1:"",
        address_2:"",
        city:"",
        county:"",
        state:"",
        zip:""
    },
    comms: {
        carrier:"",
        type:""
    },
    description:"",
    device:"",
    lastUpdate:"",
    location: {
        latitude:"",
        longitude:""
    },
    name:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    },
    parameters:[
        {
            type:"stage",
            thresholds:[]
        }
    ]
}

export default function Info(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);

    const [readings, setReadings] = useState([]);
    const [site, setSite] = useState(initSite);
    const [params, setParams] = useState([]);
    const [update, setUpdate] = useState(0);
    const navigate = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-77.447377);
    const [lat, setLat] = useState(37.548944);
    const [zoom, setZoom] = useState(9);

    const url = window.location.href;
    const siteID = url.split("=")[1];

    var key = 0;

    console.log("Info:props:",props.update);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            dragPan:false,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.scrollZoom.disable();

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        console.log("Info:site id:",siteID);

        const queryStr = {
            query: `query GetSite($id:ID!){
                getSite(id: $id) {
                    active
                    address {
                        address_1
                        address_2
                        city
                        county
                        state
                        zip
                    }
                    description
                    device
                    lastUpdate
                    location{
                        latitude
                        longitude
                    }
                    name
                    readings {
                        name
                        type
                        unit
                        MLLW
                        elevation
                        max
                        min
                        thresholds {
                            name
                            value
                            color
                        }
                    }
                }
            }`,
            variables: {
               id:siteID
            }
        }

        fetch(asURL,{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":asKey
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {
            console.log("resp:",resp.data.getSite);
            
            setReadings(resp.data.getSite.readings);
            setSite(resp.data.getSite);

            console.log("Info:site:",site);
            console.log("Info:readings:",readings);

            setLat(resp.data.getSite.location.latitude);
            setLng(resp.data.getSite.location.longitude);
            map.current.setCenter([resp.data.getSite.location.longitude,resp.data.getSite.location.latitude]);
            map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            const marker = new mapboxgl.Marker({color:"red"})
                .setLngLat([resp.data.getSite.location.longitude, resp.data.getSite.location.latitude])
                .setPopup(new mapboxgl.Popup({closeButton: false,closeOnClick: false}).setText(resp.data.getSite.name))
                .addTo(map.current);
        })

    },[update]);

    const onlineStyle = {
        color:'green'
    }

    const appBarStyle = {
        position:'fixed',
        top:0,
        zIndex:1
    }

    const backStyle = {
        position:'relative',
        color:'white',
        top:-45
    }

    const InfoIcon = {
        position:'absolute',
        right:45,
        top:5
    };

    const locationStyle = {
        position:"relative",
        width:"90%",
        borderRadius:4,
        background:"white",
        margin:"auto",
        marginTop:20,
        paddingBottom:20,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const locTitleStyle = {
        fontSize:24,
        fontWeight:500,
        marginLeft:10,
        marginTop:10
    }

    const mapStyle = {
        width:'95%',
        margin:"auto",
        marginTop:10
    }

    const sensorStyle = {
        position:"relative",
        width:"90%",
        borderRadius:4,
        background:"white",
        margin:"auto",
        marginTop:20,
        paddingBottom:20,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const siteStyle = {
        // position:"relative",
        width:"90%",
        borderRadius:4,
        background:"white",
        margin:"auto",
        marginTop:60,
        paddingBottom:20,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const tableStyle = {
        width:"90%",
        marginTop:20,
        marginLeft:40,
        fontSize:18
    }

    const thresholdStyle = {
        position:"relative",
        width:"90%",
        borderRadius:4,
        background:"white",
        margin:"auto",
        marginTop:20,
        paddingBottom:20,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const titleStyle = {
        position:"relative",
        fontSize:24,
        fontWeight:500,
        left:10,
        top:10,
    }

    const subTitleStyle = {
        position:"relative",
        fontSize:21,
        fontWeight:400,
        left:10,
        top:10,
        marginTop:10
    }

    const trStyle = {
        height:32
    }

    const rowHeader = {
        fontWeight:500
    }

    const wrapper = {
        width:'100%',
        marginBottom:20
    }

    function onGoogleMap() {
        window.open('http://www.google.com/maps/place/' + site.location.latitude + ',' + site.location.longitude,'_blank').focus();
    }

    return (
        <div className="Info" style={wrapper} onClick={props.onClick}>
            <AppBar title={site.name} style={appBarStyle}>
                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>
            </AppBar>

            <div className="site" style={siteStyle} key={useId()}>
                <div className="title" style={titleStyle}>General</div>
                <table style={tableStyle}>
                <colgroup>
                    <col width="15%"/>
                    <col width="85%"/>
                </colgroup>
                <tbody>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Status</td>
                        <td style={site.active ? {color:"green"} : {color:'red'}}>{site.active ? "Online":"Offline"}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Name</td>
                        <td>{site.name}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Description</td>
                        <td>{site.description}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Device</td>
                        <td>{site.device}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Address 1</td>
                        <td>{site.address.address_1}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Address 2</td>
                        <td>{site.address.address_2}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>City</td>
                        <td>{site.address.city}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>County</td>
                        <td>{site.address.county}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>State</td>
                        <td>{site.address.state}</td>
                    </tr>
                    <tr style={trStyle} key={key++}>
                        <td style={rowHeader}>Zip Code</td>
                        <td>{site.address.zip}</td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div className="location" style={locationStyle}>
                <div className="title" style={titleStyle}>Location</div>
                <div style={{marginTop:20, marginLeft:40, marginBottom:5}}>
                    <div style={{float:"left",fontSize:21,marginRight:10}}>Latitude:</div>
                    <div style={{float:"left",fontSize:21,marginRight:20}}>{site.location.latitude}</div>
                    <div style={{float:"left",fontSize:21,marginRight:10}}>Longitude:</div>
                    <div style={{float:"left",fontSize:21}}>{site.location.longitude}</div>
                    <img 
                        style={{width:24, marginLeft:20,marginTop:-15, cursor:"pointer"}}
                        src={googleMap} 
                        alt="Google Maps"
                        onClick={onGoogleMap}
                        title="Open Google Maps"
                    />
                </div>
                <div>
                    <div ref={mapContainer} className="map-container" style={mapStyle}/>
                </div>
            </div>

            {/* <div className="sensors" style={sensorStyle}>
                <div className="title" style={titleStyle}>Sensors</div>
                <table style={tableStyle}>
                    <colgroup>
                        <col width="20%"/>
                        <col width="20%"/>
                        <col width="65%"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th style={{textAlign:"left"}}>Make</th>
                            <th style={{textAlign:"left"}}>Model</th>
                            <th style={{textAlign:"left"}}>Interface</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        sensors.map((sensor) => {
                            return (
                                <tr style={trStyle} key={sensor.model}>
                                    <td style={rowHeader}>{sensor.make}</td>
                                    <td>{sensor.model}</td>
                                    <td>{sensor.input}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div> */}

            <div className="thresholds" style={thresholdStyle} key={key++}>
                <div className="title" style={titleStyle} key={key++}>Thresholds</div>
                {                    
                    readings.map((pr,i) => {
                        var title;

                        pr.thresholds.sort((a,b) => b.value - a.value);
                        if(pr.type == 'stage') {
                            // if(pr.unit == 'mm') {
                            //     title = pr.name + " (NAVD88: " + (pr.elevation / 304.8).toFixed(1) + " ft., MLLW: " + (pr.MLLW / 304.8).toFixed(1) + " ft.)";
                            // } else if (pr.unit == "ft") {
                            //     title = pr.name + " (NAVD88: " + (pr.elevation).toFixed(1) + " ft., MLLW: " + (pr.MLLW).toFixed(1) + " ft.)";
                            // }

                            return (
                                <div>
                                    <div className="subTitle" style={subTitleStyle}>{title}</div>
                                    <table style={{width:'90%',marginLeft:40,marginTop:20,fontSize:18}}>
                                    <colgroup key={key++}>
                                        <col width="20%"/>
                                        <col width="10%"/>
                                        <col width="60%"/>
                                    </colgroup>
                                    <thead>
                                        {
                                            <tr key={key++}>
                                                <th style={{textAlign:"left"}}>Name</th>
                                                <th style={{textAlign:"left"}}>Value</th>
                                                <th style={{textAlign:"left"}}>Color</th>
                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                    {
                                        pr.thresholds.map((th,j) => {
                                            var val;
                                            return(
                                                <tr style={trStyle} key={th.name}>
                                                    <td style={{textAlign:"left"}}>{th.name}</td>
                                                    {
                                                        pr.unit == 'mm' ? (
                                                            <td style={{textAlign:"left"}}>{(th.value / 304.8).toFixed(1) + " ft."}</td>
                                                        ) : (
                                                            <td style={{textAlign:"left"}}>{th.value + " ft."/* + "site.parameters[i].unit*/}</td>
                                                        )
                                                    }

                                                    <td style={{textAlign:"left"}}>
                                                        <div style={{background:th.color, width:50, height:"100%", borderRadius:4}}>&nbsp;
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            )

                        } else {
                            title = pr.name;
                            return (
                                <div>
                                    <div className="subTitle" style={subTitleStyle}>{title}</div>
                                    <table style={{width:'90%',marginLeft:40,marginTop:20,fontSize:18}}>
                                        <colgroup key={key++}>
                                            <col width="20%"/>
                                            <col width="10%"/>
                                            <col width="60%"/>
                                        </colgroup>
                                        <thead>
                                            {
                                                <tr key={key++}>
                                                    <th style={{textAlign:"left"}}>Name</th>
                                                    <th style={{textAlign:"left"}}>Value</th>
                                                    <th style={{textAlign:"left"}}>Color</th>
                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                        {
                                            pr.thresholds.map((th,k) => {
                                                var val;

                                                return(
                                                    <tr style={trStyle} key={th.name}>
                                                        <td style={{textAlign:"left"}}>{th.name}</td>
                                                        {
                                                            pr.unit == 'mm' ? (
                                                                <td style={{textAlign:"left"}}>{(th.value / 304.8).toFixed(1) + " ft."}</td>
                                                            ) : (
                                                                <td style={{textAlign:"left"}}>{th.value.toFixed(1)+ " " + readings[i].unit}</td>
                                                            )
                                                        }
                                                        <td style={{textAlign:"left"}}>
                                                            <div style={{background:th.color, width:50, height:"100%", borderRadius:4}}>&nbsp;
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
};